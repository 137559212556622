import node from "../assets/node.png";
import python from "../assets/python.png";
import react from "../assets/react.png";
import instagram from "../assets/instagram.png";
import linkedin from "../assets/linkedin.png";

import profile from "../assets/profile.png";
import circle from "../assets/circle.svg";
import logo from "../assets/logo.png";

const images = {
  instagram,
  linkedin,
  node,
  python,
  react,
  profile,
  circle,
  logo,
};

export default images;
